*, ::after, ::before{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root{
  --grey: #CCCCCC;
  --yellow: #FFCC00;
  --blue: #003773;
  --darkBlue: #00174C;
  --red: #FF004C;
}

body{
  /* background-color: #CCCCCC; */
}