.loginWrapper{
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    background: #faf9f6;
}

.loginCard{
    border: 1px solid var(--grey);
    border-radius: 16px;
    padding: 20px;
    width: 100%;
    max-width: 350px;
    background-color: white;
    box-shadow:  10px 10px 33px #e4e3e0,
             -10px -10px 33px #ffffff;
}

.loginCard h2{
    text-align: center;
    margin-bottom: 20px;
    margin-top: 10px;
}

.loginCard form > div:not(:last-child){
    margin-bottom: 8px;
}

.loginCard label{
    margin-bottom: 4px;
    display: inline-block;
}

.loginCard input{
    width: 100%;
    padding: 6px 8px;
    border-radius: 4px;
    border: 1px solid var(--grey);
    font-size: 16px;
}

.loginCard input:focus{
    outline: none;
    border: 1px solid var(--yellow);
}

.loginCard .mainBtn{
    width: 100%;
    font-size: 16px;
    padding: 9px 0;
    border: none;
    color: white;
    background-color: var(--darkBlue);
    border-radius: 8px;
    cursor: pointer;
    margin-top: 10px
}

.loginCard .mainBtn img{
    margin: auto;
    animation-name: rotate2;
    animation-duration: 0.4s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes rotate2 {
    100%{
        transform: rotate(360deg);
    }
}

.loginCard .mainBtn:hover{
    background-color: var(--blue);
    transition: background-color 100ms linear;
}

.loginCard .message{
    background: var(--red);
    color: white;
    margin-top: 10px;
    border-radius: 4px;
    text-align: center;
}